body{
  background: #eaebe4;
}
.App {
  text-align: left;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.amplify-tabs {
  display: none;
}
.amplify-collection-items > *{
 background-color: #eaebe4 !important; 
}
/*.amplify-button[data-variation=primary] {
  background-color: hsl(32deg 11% 27%) !important;
}*/

#radix-id-0-2-content-1 > div > div > form > div:nth-child(2) {
  display: none;
}

#radix-id-0-2-content-1 > div > div > form > div:nth-child(1) > div {
  display: none;
}
.user span{
  font-size: 8px;
}
div[data-amplify-router]{
  box-shadow: none !important;
}

.App-link {
  color: #61dafb;
}

.selectall-btn {
  padding: 6px;
  font-size: 12px !important;
  line-height: 10px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
